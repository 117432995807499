import React, { useState, useEffect, useCallback, useMemo } from 'react'

/** Utils */
import { withRouter, Redirect } from 'react-router'
import { getCompanyCards, getCompanyCardsPersonal, buyAttendees, getAttendeeService, getEvent, getEventCartItem, getBillingCouponInfoByPromoCode, calculateTaxByCurrency, checkoutCart } from '../api/calls'
import queryString from 'query-string'

/** Components */
import Button from 'react-bootstrap/Button'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import { ReactComponent as CheckSvg } from '../assets/svg/check.svg'
import { ReactComponent as CrossSvg } from '../assets/svg/close.svg'

/** Assets */
import VisaCard from '../assets/card-providers/dark/visa.png'


const EventCart = (props) => {
  const { match, stripe, elements, location } = props

  const [pricingInfo, setPricingInfo] = useState(null)
  const [event, setEvent] = useState(null)
  const [cartItems, setCartItems] = useState([])
  const [tax, setTax] = useState({})

  const totalPrice = useMemo(() => cartItems.reduce((acc, curr) => acc + curr.quantity * curr.pricing.price, 0), [cartItems])
  const totalTax = useMemo(() => cartItems.reduce((acc, curr) => acc + (isNaN(curr.pricing.tax) ? 0 : (curr.quantity * curr.pricing.price * curr.pricing.tax / 100)), 0), [cartItems])
  const currency = useMemo(() => cartItems.length > 0 ? cartItems[0].pricing?.currency : '', [cartItems])

  const [promoCode, setPromoCode] = useState('')
  const [promoCodeId, setPromoCodeId] = useState('')
  const [promoCodeAmount, setPromoCodeAmount] = useState(0)
  const [isPromoCode, setIsPromoCode] = useState(false)

  const [selectedCard, setSelectedCard] = useState(null)
  const [companyCards, setCompanyCards] = useState([])

  const [confirmStep, setConfirmStep] = useState(0)
  const [intentClientSecret, setIntentClientSecret] = useState(null)

  const getTax = useCallback(async () => {
    if (!pricingInfo) return
    const taxInfo = await calculateTaxByCurrency((pricingInfo.price / 100) - promoCodeAmount, pricingInfo?.currency)
    setTax({
      taxPayable: taxInfo?.data?.calculateTaxByCurrency?.payable,
      taxRate: taxInfo?.data?.calculateTaxByCurrency?.rate?.rate,
      taxType: taxInfo?.data?.calculateTaxByCurrency?.rate?.type,
    })
  }, [pricingInfo])

  const getEventProps = useCallback(async () => {
    try {
      if (!match?.params?.eventId) return
      const { data } = await getEvent(match?.params?.eventId)
      const { data: dataCart } = await getEventCartItem(match?.params?.eventId)

      setEvent(data.getEvent)
      setCartItems((dataCart.getEventCartItem || []).filter(item => item.status === 'PENDING' || item.status === 'PAYMENT_FAILED'))
    } catch (error) {
      console.log(error)
    }
  }, [match?.params?.eventId])

  const getCards = useCallback(async () => {
    try {
      const { data: { getPaymentCardsPersonal: result } } = await getCompanyCardsPersonal()
      console.log("Company: ", event?.organiser?.company)
      setCompanyCards(result)
    } catch (error) {
      console.log(error)
    }
  }, [event?.organiser?.company?.id])

  const getService = useCallback(async () => {
    try {
      if (!event) return
      const { data } = await getAttendeeService(event?.id)

      setPricingInfo({
        serviceId: data.getAttendeeService.service.id,
        price: data.getAttendeeService.price,
        quantity: data.getAttendeeService.amount,
        serviceName: data.getAttendeeService.service.name,
        currency: data.getAttendeeService?.currency,
        companyName: data.getAttendeeService.company.name,
        taxName: data.getAttendeeService.company.taxName,
        taxAmount: data.getAttendeeService.company.taxAmount
      })

    } catch (error) {
      console.log(error)
    }
  }, [event])

  const checkoutItem = useCallback(async () => {
    try {

      let { data } = await checkoutCart(event?.id, selectedCard.stripeId, promoCodeId)

      if (data && data.checkoutEventCartItemWithCard && !intentClientSecret) {

        setIntentClientSecret(data.checkoutEventCartItemWithCard.client_secret)

        setConfirmStep(2)
      }
    } catch (error) {
      console.log(error)
    }
  }, [event, pricingInfo, promoCodeId, selectedCard])

  const formatNumber = (value) => {
    if (value) {
      let numParts = value.toString().split('.');
      numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return numParts.join('.');
    }
    return value
  }

  const renderCurrencyFormatter = (price, currency) => {
    if (!currency) return price
    const formatter = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: currency
    })

    return formatter.format(price)
  }

  const applyPromo = useCallback(async () => {
    let { errors, data } = await getBillingCouponInfoByPromoCode(promoCode, 'event-cart')

    if (errors) return alert(errors[0].message)

    const { getBillingCouponInfoByPromoCode: { value, unit, id, stripeCouponId } } = data
    if (id) {
      setPromoCodeId(id)
      setIsPromoCode(true)
      setPromoCodeAmount(unit === 'PERCENTAGE' ? (totalPrice) * (value / 100) : value)
      getTax()
    } else {
      alert('No promo code found')
    }
  }, [promoCode])

  const cancelPromo = async () => {
    setPromoCodeId('')
    setIsPromoCode(false)
    setPromoCodeAmount(0)
    setPromoCode('')
    getTax()
  }

  const getCardBrandImage = (brand) => {
    brand = brand.toLowerCase()

    let cardImage

    switch (brand) {
      case 'visa':
        cardImage = VisaCard
        break
      default:
        break
    }

    if (cardImage) {
      return <img alt={brand} width='40px' src={cardImage} />
    }
  }

  const renderCardDropdownLine = (card) => {
    return (
      <span>{getCardBrandImage(card?.brand)} **** **** **** {card?.last4}</span>
      // <span>{getCardBrandImage(card?.brand)} **** **** **** {card?.last4} (exp: {card?.exp_month}/{card?.exp_year})</span>
    )
  }

  const doCancel = useCallback(() => {
    // const parsedQuery = queryString.parse(location.search)
    // window.location.replace(parsedQuery['callback'] || 'https://synkd.life')

    let url =
    window.location != window.parent.location
        ? document.referrer
        : document.location.href;
/* window.parent.postMessage("close-iframe", 'https://localhost:8080/admin/company/adminpackageoptions'); */
    window.parent.postMessage("close-iframe", '*');
  }, [location.search])

  useEffect(() => {
    getEventProps()
    console.log(match)
  }, [getEventProps])

  useEffect(() => {
    getService()
  }, [getService])

  useEffect(() => {
    getCards()
  }, [getCards])

  useEffect(() => {
    getTax()
  }, [getTax])

  useEffect(() => {
    if (confirmStep === 1) {
      checkoutItem()
    }
  }, [confirmStep, checkoutItem])

  if (confirmStep === 2) {
    return (
      <Redirect
        to={{
          pathname: `/complete-transaction`,
          search: (location.search ? location.search : null),
          state: { companyId: event?.organiser?.company?.id, card: selectedCard, intentClientSecret, serviceName: pricingInfo?.serviceName }
        }}
      />
    )
  }

  return (
    <div className='main-container'>
      <div className='content-container animate__animated animate__fadeIn'>
        <div className='action-btns'>
          <CheckSvg
            width='40px'
            height='40px'
            className='p-2 rounded'
            color='whitesmoke'
            style={{ backgroundColor: '#a489ac', cursor: 'pointer' }}
            disabled={!selectedCard}
            onClick={() => setConfirmStep(1)}
          />
          <CrossSvg
            width='40px'
            height='40px'
            className='p-2 rounded'
            color='whitesmoke'
            onClick={() => doCancel()}
            style={{ backgroundColor: '#ed1c24', cursor: 'pointer', marginLeft: "8px" }}
          />
        </div>
        <h5 className="modal-title" style={{ marginTop: '10px' }}>Purchase</h5>

        <div>
          {
            cartItems.map(item => (
              <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0', padding: '0 10px' }}>
                <div>{item.quantity} {item.item_detail.name}</div>
                <div>{renderCurrencyFormatter(item.quantity * item.pricing.price, item.pricing?.currency)}</div>
              </div>
            ))
          }
        </div>

        <div className='purchase-method card-method animate__animated animate__fadeIn'>
          <DropdownButton id="card-dropdown" variant='secondary' title={selectedCard ? renderCardDropdownLine(selectedCard) : 'Select Card'}>
            <Dropdown.ItemText>Saved company cards</Dropdown.ItemText>
            {
              companyCards.map((card) => {
                return (
                  <Dropdown.Item as="button" key={card.stripeId} onClick={() => setSelectedCard(card)}>
                    {renderCardDropdownLine(card)}
                  </Dropdown.Item>
                )
              })
            }
          </DropdownButton>
        </div>

        <div className="price-package">
          <p>Total</p>
          <p>
            <strong>{renderCurrencyFormatter(totalPrice, currency)}</strong>
          </p>
        </div>
                            
          <div style={{marginTop: '10px'}}>
              <input
                type="text"
                name="promo"
                placeholder="Promo code"
                value={promoCode}
                disabled={isPromoCode}
                onChange={(e) => setPromoCode(e.target.value)}
              />
              {
                isPromoCode ? (
                  <Button
                    variant="synkdPrimary"
                    className="btn-promo"
                    onClick={() => cancelPromo()}
                    style={{marginLeft: '5px'}}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    variant="synkdPrimary"
                    className="btn-promo"
                    onClick={() => applyPromo()}
                    style={{marginLeft: '5px'}}
                  >
                    Apply
                  </Button>
                )
              }
          </div>

        <div className="price-package">
          {/* <p>{tax.taxType}</p> */}
          <p>Tax</p>
          <p>
            <strong>{renderCurrencyFormatter(totalTax, currency)}</strong>
          </p>
          {
            isPromoCode && (
              <>
                <p>Discount</p>
                <p>
                  <strong>{renderCurrencyFormatter(promoCodeAmount, pricingInfo?.currency)}</strong>
                </p>
              </>
            )
          }
          <p className="grand">Grand Total</p>
          <p className="grand">
            <strong>{renderCurrencyFormatter((totalPrice - promoCodeAmount) + totalTax, currency)}</strong>
          </p>
        </div>
      </div>
    </div>
  )                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
}

export default withRouter(EventCart)

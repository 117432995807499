import React from 'react'
import { withRouter } from "react-router"

import Stripe from '../stripe/index'

import logo from '../assets/synkd_logo.png'
import VisaCard from '../assets/card-providers/dark/visa.png'
import { ReactComponent as CheckSvg } from '../assets/svg/check.svg'
import { ReactComponent as CrossSvg } from '../assets/svg/close.svg'
import GeneralDisclaimer from '../components/GeneralDisclaimer'
import Loading from '../components/Loading'
import Error from '../components/Error'
import Success from '../components/Success'

import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import moment, { Moment } from 'moment'

import { getCompanyCoupon, getCompanyCards, getInternalProductInfoForCompany, getPricingInfo, createCompanySubscription, getBillingCouponInfoByPromoCode, getMe, calculateTaxByCurrency } from '../api/calls'
import { mockComponent } from 'react-dom/test-utils'
const queryString = require('query-string')
class Subscription extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            companyId: '',
            internalProductId: '',
            productName: '',
            productDescription: '',
            productCurrency: '',
            productPriceId: '',
            productPrice: '',
            productRecurringInfo: {},
            companyCards: [],
            selectedCard: null, // set if the user selects a card from the dropdown
            errorMessage: '',
            loading: true,
            fatalError: false,
            error: '',
            success: false,
            coupon: null,
            productTaxName: 'Sales Tax',
            productTaxAmount: 0.06,
            startDate: moment().format("ddd MMM DD YYYY"),
            endDate: moment().add(1, 'months').format("ddd MMM DD YYYY"),
            isPromoCode: false,
            isSignupCoupon: false,
            promoCode:'',
            promoCodeAmount:0,
            taxPayable: 0, // populated by API call to calculateTax
            taxRate: 0,
            taxType: '',
            requiresAction: false,
            actionUrl: '',
            intentClientSecret: ''
        }

        this.stripe = new Stripe()
    

        this.doConfirm = this.doConfirm.bind(this)

        this.getCardBrandImage = this.getCardBrandImage.bind(this)
        this.setSelectedCard = this.setSelectedCard.bind(this)
        this.renderCardDropdownLine = this.renderCardDropdownLine.bind(this)
        this.populateSavedCardsDropdown = this.populateSavedCardsDropdown.bind(this)
        this.closeIframe = this.closeIframe.bind(this)
    }


    on3DSComplete = async() => {
        const parsedQuery = queryString.parse(this.props.location.search)

        // Check the PaymentIntent
        this.stripe._stripe.retrievePaymentIntent(this.state.intentClientSecret)
          .then(async(result) => {
            if (result.error) {
              // PaymentIntent client secret was invalid
              this.setState({
                error: result.error.message,
                success: false,
                requiresAction: false
              })
        
            } else {
              if (result.paymentIntent.status === 'succeeded') {
                // Show your customer that the payment has succeeded
                this.setState({
                    success: true,
                    requiresAction: false
                })
              } else if (result.paymentIntent.status === 'requires_payment_method') {
                console.log('3DS Failed.', result.paymentIntent.status)
                // Authentication failed, prompt the customer to enter another payment method
                this.setState({
                    error: null,
                    success: false,
                    requiresAction: false
                })

              } else {
                console.log("3DS Failed.", result.paymentIntent.status)
                this.setState({
                    error: null,
                    success: false,
                    requiresAction: false
                })
              }
            }
            console.log("callback: ",parsedQuery['callback'])
            if (parsedQuery['callback']) {
                // Redirect to the callback URL
                
                console.log("Redirect to the callback URL")
                await new Promise(resolve => setTimeout(resolve, 1000 * 3)) // timeout for 3 sec
                window.location.replace(parsedQuery['callback'])
              
            }
          });
      }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.fatalError !== this.state.fatalError) {
            if (prevState.loading !== false) {
                // Stop loading on fatal error
                this.setState({
                    loading: false
                })
            }
        }

        window.addEventListener('message', (ev) => {
            if (ev.data === '3DS-authentication-complete') {

              this.on3DSComplete()
            }
        }, false);
    }

    getCardBrandImage(brand) {
        brand = brand.toLowerCase()

        let cardImage

        switch (brand) {
            case 'visa':
                cardImage = VisaCard
                break
            default:
                break
        }

        if (cardImage) {
            return <img alt={brand} width='40px' src={cardImage} />
        }
    }

    closeIframe() {
        let url =
          window.location != window.parent.location
            ? document.referrer
            : document.location.href;
        /* window.parent.postMessage("close-iframe", 'https://localhost:8080/admin/company/adminpackageoptions'); */
        window.parent.postMessage("close-iframe", '*');
      };

    setSelectedCard(cardStripeId) {
        let card = this.state.companyCards.filter((c) => {
            return c.stripeId === cardStripeId
        })

        if (card.length > 0) card = card[0] // set to the first card (there should only be one matching this anyway)

        this.setState({
            selectedCard: card
        })
    }

    renderCardDropdownLine(c) {
        return <span>{this.getCardBrandImage(c.brand)} **** **** **** {c.last4} </span>
    }

    populateSavedCardsDropdown() {
        let dropItems = this.state.companyCards.map((c) => {
            return (
                <Dropdown.Item as="button" key={c.stripeId} onClick={() => this.setSelectedCard(c.stripeId)}>
                    {this.renderCardDropdownLine(c)}
                </Dropdown.Item>
            )
        })

        return dropItems
    }

    renderCurrencyFormatter(price,currency) {
        if (this.state.productPrice !== null) {
            var formatter = new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: currency
            })
    
            return formatter.format(price)
        }
    }

    async componentDidMount() {
        const { match } = this.props
        // let companyCoupons = await getCompanyCoupon()
        // let signupCoupon = companyCoupons.filter((coupon) => coupon.isSignupCoupon && coupon.currentUses === null || 0)
        this.setState({
            companyId: match.params.companyId,
            internalProductId: match.params.internalProductId
        })

        let productInfo = await getInternalProductInfoForCompany(match.params.companyId, match.params.internalProductId)
        if (productInfo.data && productInfo.data.getInternalProductInfoForCompany) {
            const product = productInfo.data.getInternalProductInfoForCompany
            console.log('product', product);
            const prices = (product.prices || []).filter(Boolean)
            if (prices.length > 0) {
                let pricing = prices[0]

                this.setState({
                    productName: product.name,
                    productDescription: product.description,
                    productCurrency: pricing?.currency,
                    productPriceId: pricing.stripePriceId,
                    productTaxName: product.taxName,
                    productTaxAmount:product.taxAmount
                })

                // Get Stripe pricing info
                const price = await getPricingInfo(pricing.stripePriceId)
                if (price.data && price.data.getPricingInfo) {
                    let priceObj = price.data.getPricingInfo
                    
                    console.log('PRICE', priceObj)
                    this.setState({
                        productPrice: priceObj.unit_amount,
                        productRecurringInfo: priceObj.recurring
                    })

                    const taxInfo = await calculateTaxByCurrency(priceObj.unit_amount / 100, pricing?.currency);
                    this.setState({
                        taxPayable: taxInfo?.data?.calculateTaxByCurrency?.payable,
                        taxRate: taxInfo?.data?.calculateTaxByCurrency?.rate?.rate,
                        taxType: taxInfo?.data?.calculateTaxByCurrency?.rate?.type,
                    })

                    // Now get all company cards and populate
                    console.log('cards companyId', this.state.companyId)
                    const res = await getCompanyCards(this.state.companyId)
                    console.log('res get Compnay Cards', res)
                    let newCompanyCards = []
        
                    if (res.data && res.data.getPaymentCards) {
                        newCompanyCards = res.data.getPaymentCards
                    }
        
                    this.setState({
                        companyCards: newCompanyCards,
                        loading: false
                    })        
                } else {
                    this.setState({
                        errorMessage: 'Could not find pricing for this subscription. Please go back, and try again.',
                        fatalError: true
                    })
                }
            } else {
                this.setState({
                    errorMessage: 'Could not find pricing for this subscription. Please go back, and try again.',
                    fatalError: true
                })
            }
        } else {
            this.setState({
                errorMessage: 'There was a problem loading details for this subscription. Please go back, and try again.',
                fatalError: true
            })
        }        
        // disable this as per pt request (come back to later)
        // if (signupCoupon) {
        // this.setState({
        //  promoCode: signupCoupon[0].name,
        //  isSignupCoupon: true
        // })
        // this.applyPromo()
        // }
       
    }

    async applyPromo(){
        //Put Code for Apply Promocode
        let { errors, data } = await getBillingCouponInfoByPromoCode(this.state.promoCode, 'subscription')
        if (errors) return alert(errors[0].message)
        const { getBillingCouponInfoByPromoCode: { value, unit, id, stripeCouponId  } } = data
        if (id) {
            this.setState({
                promoCodeId: id,
                coupon: stripeCouponId,
                isPromoCode: true,
                promoCodeAmount: unit === 'PERCENTAGE' ? (this.state.productPrice / 100) * value / 100 : value
            }, async () => {
                const taxInfo = await calculateTaxByCurrency((this.state.productPrice / 100) - this.state.promoCodeAmount, this.state.productCurrency);
                this.setState({
                    taxPayable: taxInfo?.data?.calculateTaxByCurrency?.payable,
                    taxRate: taxInfo?.data?.calculateTaxByCurrency?.rate?.rate,
                    taxType: taxInfo?.data?.calculateTaxByCurrency?.rate?.type,
                })
            })
        } else {
            alert('No promo code found')
        }
    }


    cancelPromo(){
        //Put Code for Cancel Promocode
        this.setState({
            isPromoCode:false,
            promoCodeAmount:0,
            // isSignupCoupon: false,
            promoCode:'',
            coupon: null
        }, async () => {
            const taxInfo = await calculateTaxByCurrency((this.state.productPrice / 100) - this.state.promoCodeAmount, this.state.productCurrency);
            this.setState({
                taxPayable: taxInfo?.data?.calculateTaxByCurrency?.payable,
                taxRate: taxInfo?.data?.calculateTaxByCurrency?.rate?.rate,
                taxType: taxInfo?.data?.calculateTaxByCurrency?.rate?.type,
            })
        })
    }

  
    handle3DSecure = async(subscription, invoice, isRetry) => {
       
       // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
        // If it's a retry, the payment intent will be on the invoice itself.
        let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent
    
       
        
        if (subscription && subscription.status === 'active' && paymentIntent.status === "succeeded") {
        
            this.setState({
                success: true,
                requiresAction: false,
                error: null
            })
        
            // The subscription is active, no need to complete this
            return { subscription, invoice }
        }


        
        
         await this.stripe.loadStripe()

         this.setState({
          
            intentClientSecret: paymentIntent.client_secret
        })

          // Confirm the card payment using the intent client secret
          let stripeRes = await this.stripe._stripe.confirmCardPayment(paymentIntent.client_secret,
            {
              //payment_method: {card: card},
              return_url: `${window.origin}/3DS-authentication-complete`
            },
            // Disable the default next action handling.
            {handleActions: false}
          )

    
      
      
      // stripeRes.paymentIntent for success, stripeRes.error for errors
      if (stripeRes.error) {
          this.setState({
              error: stripeRes.error.message,
              success: false
          })
      } else {

          let paymentIntent = stripeRes.paymentIntent
          
          

          if (
              typeof paymentIntent === 'object' &&
              (paymentIntent.status === 'requires_source_action' ||
                paymentIntent.status === 'requires_action') &&
              paymentIntent.next_action &&
              paymentIntent.client_secret
            ) {

              let action = paymentIntent.next_action;

              if (action && action.type === 'redirect_to_url') {
                  this.setState({
                      requiresAction: true,
                      actionUrl: action.redirect_to_url.url
                  })
                  //window.location.replace(action.redirect_to_url.url);
              }
            } else {
                console.log("Subscription->> No action required")
            }

      }
      
    }

    async doConfirm() {
        // Reset error message
        this.setState({
            errorMessage: ''
        })

        let res = await createCompanySubscription(this.state.companyId, [this.state.productPriceId], this.state.selectedCard.stripeId, this.state.coupon)

        if (res.errors) {
            this.setState({
                errorMessage: 'There was an error creating your subscription. Please try again.'
            })
        } else {
            let subscription = res.data.createCompanySubscription,
                invoice = subscription.latest_invoice


            // If they've used a 3D Secure payment method, we will need to handle authentication
            await this.handle3DSecure(subscription, invoice)

            if (!this.state.errorMessage) {
                // If there is no error message, the flow must have been successful
                // this.setState({ 
                //     success: true
                // })
                 
            }
        }
    }

    render() {

        if(this.state.requiresAction){
            return (
                <iframe
                    className="iframe"
                    title="3DS verification"
                    src={this.state.actionUrl}
                    width={600}
                    height={400}
                />
            )
        } else if (this.state.error === null && this.state.success === false) return <Error>
            <p>Subscription failed</p>
            <p>Please try again.</p>
        </Error>
        else if (this.state.loading) return <Loading />
        else if (this.state.fatalError) return <Error>{this.state.errorMessage}</Error>  
        else if (this.state.success) return <Success>
            <p>Your subscription has started and will continue until you cancel. You can manage your subscription from your profile.</p>
        </Success>
       
        let products = this.state.productDescription.replace("and ","").split(', ');
        return (
            <>
            
           
            <div className='main-container'>
                <div className='content-container animate__animated animate__fadeIn'>
                   <div className='action-btns'>
                   
            <CheckSvg
              width='40px'
              height='40px'
              className='p-2 rounded'
              color='whitesmoke'
              style={{ backgroundColor: '#a489ac', cursor: 'pointer' }}
              warning={this.state.formWarning} 
              variant="synkdPrimary" 
              disabled={this.state.selectedCard === null} 
              onClick={() => this.doConfirm()} 
            //   className='pay-btn'
            />
            <CrossSvg 
              width='40px' 
              height='40px' 
              className='p-2 rounded' 
              color='whitesmoke' 
              onClick={() => this.closeIframe()} 
            //   variant="danger" 
            //   className='cancel-btn'
              style={{ backgroundColor: '#ed1c24', cursor: 'pointer', marginLeft: "8px" }} 
            />
                                   <div>

          </div>
                  
                    </div>
                    
                    <h5 className="modal-title" style={{marginTop: '10px'}}>Monthly {this.state.productName} Package</h5>
                    {products.map((product, index) => (
                            <p key={index} className="product">{product}</p>
                    ))}
<div className='center'>
<div className='purchase-method card-method animate__animated animate__fadeIn'>
                        {/* <h5>Select card</h5> */}
                        <DropdownButton id="card-dropdown" variant='secondary' title={this.state.selectedCard ? this.renderCardDropdownLine(this.state.selectedCard) : 'Select Card'}>
                            <Dropdown.ItemText>Saved company cards</Dropdown.ItemText>
                            {this.populateSavedCardsDropdown()}
                        </DropdownButton>
                    </div>
                    {this.state.errorMessage && <p className='error'>{this.state.errorMessage}</p>}
                    <div className="price-package">
                    <p>{this.state.productName} Package</p>
                    <p><strong>{this.renderCurrencyFormatter((this.state.productPrice / 100),this.state.productCurrency)}</strong></p>
                    </div>
                    {/*  */}
                    <div  style={{marginTop: '10px'}}>  
                       <input type="text" name="promo" placeholder="Promo code" value={this.state.isSignupCoupon ? '*******' : this.state.promoCode} disabled={this.state.isPromoCode} onChange={(e) => {console.log(e); this.setState({ promoCode: e.target.value })}} />
                    {this.state.isPromoCode?(<Button variant="synkdPrimary" className="btn-promo" onClick={() => this.cancelPromo()} style={{marginLeft: '5px'}} >Cancel</Button>):(<Button variant="synkdPrimary" className="btn-promo" onClick={() => this.applyPromo()} style={{marginLeft: '5px'}} > ✓ </Button>)}
                    </div>
                    {/* { this.state.isSignupCoupon &&
                    (<p>
                    you get 15% off your first subscription!
                    </p>)} */}
                    <div backgroundColor="#643e73">
                    <p>{this.state.taxType}</p>
                    <p><strong>{this.renderCurrencyFormatter(this.state.taxPayable, this.state.productCurrency)}</strong></p>
                    {this.state.isPromoCode?(
                        <p>Discount</p>
                    ):''}
                    {this.state.isPromoCode?(
                        <p><strong>{this.renderCurrencyFormatter(this.state.promoCodeAmount,this.state.productCurrency)}</strong></p>
                    ):''}
                    <p className="grand">Grand Total</p>
                    <p className="grand"><strong>{this.renderCurrencyFormatter((this.state.productPrice / 100) - this.state.promoCodeAmount + this.state.taxPayable, this.state.productCurrency)}</strong></p>
                    </div>
             
                    <div className="date-package">
                    <p>Start Date</p>
                    <p className="date-start">{this.state.startDate}</p>
                    <p>Next Billing Date</p>
                    <p className="date-end">{this.state.endDate}</p>
                    {/* <p>Of the {this.state.productName} Package</p> */}
                    </div>
                           
       
                </div>
                <GeneralDisclaimer />
                </div>
            </div>

            </>
        )
    }
}

export default withRouter(Subscription)

import React from 'react'
import { withRouter, Redirect } from "react-router"
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js'

import logo from '../assets/synkd_logo.png'

import GeneralDisclaimer from '../components/GeneralDisclaimer'
import { ReactComponent as CheckSvg } from '../assets/svg/check.svg'
import { ReactComponent as CrossSvg } from '../assets/svg/close.svg'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'

import VisaCard from '../assets/card-providers/dark/visa.png'

import { getCompanyCards, startTopupTransaction, getMyPricingInfoForService, getBillingCouponInfoByPromoCode, calculateTaxByCurrency } from '../api/calls'

const queryString = require('query-string')

class Topup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      companyId: null,
      quantity: null,
      serviceId: null,
      serviceName: null, // populated by API call to getMyPricingInfoForService
      price: null, // populated by API call to getMyPricingInfoForService
      currency: null, // populated by API call to getMyPricingInfoForService
      companyName: null, // populated by API call to getMyPricingInfoForService,
      canUsePaymentRequest: false,
      method: null,
      taxPayable: 0, // populated by API call to calculateTax
      companyCards: [], // populated as soon as 'method' is 'card'
      companyCardsLoaded: false, // updated as soon as company cards are loaded
      selectedCard: null, // set if the user selects a card from the dropdown
      confirmStep: 0, // 1 after clicking Pay, 2 after PaymentIntent is created
      intentClientSecret: null, // set when a stripe intent is created (after clicking the pay button)
      callback: null,
      taxAmount: 0,
      taxName: 'Sales Tax',
      isPromoCode: false,
      promoCodeId: '',
      promoCode: '',
      promoCodeAmount: 0,
      taxRate: 0,
      taxType: ''
    }

    this.stripePaymentRequest = null

    this.selectMethod = this.selectMethod.bind(this)
    this.getCardBrandImage = this.getCardBrandImage.bind(this)
    this.setSelectedCard = this.setSelectedCard.bind(this)
    this.renderCardDropdownLine = this.renderCardDropdownLine.bind(this)
    this.populateSavedCardsDropdown = this.populateSavedCardsDropdown.bind(this)
    this.renderMethod = this.renderMethod.bind(this)
    this.continuePayment = this.continuePayment.bind(this)
  }

  selectMethod(method) {
    this.setState({
      method
    })
  }

  async componentDidMount() {
    const { match } = this.props
    this.setState({
      companyId: match.params.companyId,
      quantity: match.params.quantity,
      serviceId: match.params.serviceId
    })
    let pricingInfo = await getMyPricingInfoForService(match.params.companyId, match.params.serviceId, match.params.quantity)
    if (pricingInfo.data && pricingInfo.data.getMyPricingInfoForService) {
      this.setState({
        price: pricingInfo.data.getMyPricingInfoForService.price,
        serviceName: pricingInfo.data.getMyPricingInfoForService.name,
        currency: pricingInfo.data.getMyPricingInfoForService?.currency,
        companyName: pricingInfo.data.getMyPricingInfoForService.company.name,
        taxName: pricingInfo.data.getMyPricingInfoForService.company.taxName,
        taxAmount: pricingInfo.data.getMyPricingInfoForService.company.taxAmount
      })

      const taxInfo = await calculateTaxByCurrency(pricingInfo.data.getMyPricingInfoForService.price / 100, pricingInfo.data.getMyPricingInfoForService?.currency);
      this.setState({
        taxPayable: taxInfo?.data?.calculateTaxByCurrency?.payable,
        taxRate: taxInfo?.data?.calculateTaxByCurrency?.rate?.rate,
        taxType: taxInfo?.data?.calculateTaxByCurrency?.rate?.type,
      })

      // Setup the PaymentRequest object
      const { stripe, elements } = this.props

      if (!stripe || !elements) {
        // Stripe.js has not loaded, for some reason. Don't allow PaymentRequest
        return
      }

      this.stripePaymentRequest = await stripe.paymentRequest({
        country: 'MY', // Country code of OUR Stripe account (Malaysia)
        currency: this.state?.currency.toLowerCase(),
        total: {
          label: `Top-up for ${this.state.companyName}`,
          amount: this.state.price
        },
        requestPayerName: true,
        requestPayerEmail: true
      })

      this.stripePaymentRequest.canMakePayment().then(result => {
        if (result) {
          console.log(result)
          this.setState({
            canUsePaymentRequest: true
          })
        }
      })

      this.stripePaymentRequest.on('paymentmethod', async (ev) => {

      })
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (!this.state.companyCardsLoaded) {
      // If the user has selected the card payment method, retrieve the saved company cards
      const res = await getCompanyCards(this.state.companyId)
      let newCompanyCards = []

      if (res.data && res.data.getPaymentCards) {
        newCompanyCards = res.data.getPaymentCards
      }

      this.setState({
        companyCards: newCompanyCards,
        companyCardsLoaded: true
      })
    }
    // let tax = await calculateTax(this.state.price, this.state.selectedCard.countryCode)
    // if (this.state.selectedCard !== prevState.selectedCard) {
    //     // If a new card is selected, calculate the tax to display
    //     let tax = await calculateTax(this.state.price, this.state.selectedCard.countryCode)
    //     this.setState({
    //         taxPayable: tax.data.calculateTax.payable
    //     })
    // }

    if ((this.state.confirmStep !== prevState.confirmStep) && this.state.confirmStep === 1) {
      // Create a new Stripe payment intent
      let intent = await startTopupTransaction(this.state.companyId, this.state.serviceId, this.state.quantity, this.state.selectedCard.stripeId, this.state.promoCodeId)
      if (intent.data && intent.data.startTopupTransaction) {

        if (this.state.intentClientSecret === null) {
          this.setState({
            intentClientSecret: intent.data.startTopupTransaction
          })
        }
      }
    }

    if (this.state.intentClientSecret !== null && (this.state.intentClientSecret !== prevState.intentClientSecret)) {
      console.log('Continuing payment to 2')
      // If we have created a valid PaymentIntent, we can continue through to the next page
      this.continuePayment(2)
    }
  }

  getCardBrandImage(brand) {
    brand = brand.toLowerCase()

    let cardImage

    switch (brand) {
      case 'visa':
        cardImage = VisaCard
        break
      default:
        break
    }

    if (cardImage) {
      return <img alt={brand} width='40px' src={cardImage} />
    }
  }

  setSelectedCard(cardStripeId) {
    let card = this.state.companyCards.filter((c) => {
      return c.stripeId === cardStripeId
    })

    if (card.length > 0) card = card[0] // set to the first card (there should only be one matching this anyway)

    this.setState({
      selectedCard: card
    })
  }

  renderCardDropdownLine(c) {
    return <span>{this.getCardBrandImage(c.brand)} **** **** **** {c.last4} </span>
  }

  populateSavedCardsDropdown() {
    let dropItems = this.state.companyCards.map((c) => {
      return (
        <Dropdown.Item as="button" key={c.stripeId} onClick={() => this.setSelectedCard(c.stripeId)}>
          {this.renderCardDropdownLine(c)}
        </Dropdown.Item>
      )
    })

    return dropItems
  }

  renderMethod() {
    // switch (this.state.method) {
    //     case 'card':
    return (
      <div className='purchase-method card-method animate__animated animate__fadeIn'>
        {/* <h5>Select card</h5> */}
        <DropdownButton id="card-dropdown" variant='secondary' title={this.state.selectedCard ? this.renderCardDropdownLine(this.state.selectedCard) : 'Select Card'}>
          <Dropdown.ItemText>Saved company cards</Dropdown.ItemText>
          {this.populateSavedCardsDropdown()}
        </DropdownButton>
        {/* {this.state.selectedCard &&
                            <Form.Check type="checkbox" label="Set this card as my default for future transactions" />
                        } */}
      </div>
    )
    //     default:
    //         return
    // }
  }

  renderCurrencyFormatter(price, currency) {
    if (this.state.price !== null) {
      var formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: currency
      })

      return formatter.format(price)
    }
  }

  continuePayment(step) {
    this.setState({
      confirmStep: step
    })
  }

  renderTax() {
    if (this.state.taxPayable !== null) {
      var formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: this.state?.currency
      })

      return formatter.format(this.state.taxPayable)
    }
  }

  doCancel() {
    // const parsedQuery = queryString.parse(this.props.location.search)
    // if (parsedQuery['callback']) {
    //   // Redirect to the callback URL
    //   window.location.replace(parsedQuery['callback'])
    // } else {
    //   window.location.replace('https://synkd.life')
    // }

    let url =
    window.location != window.parent.location
        ? document.referrer
        : document.location.href;
/* window.parent.postMessage("close-iframe", 'https://localhost:8080/admin/company/adminpackageoptions'); */
window.parent.postMessage("close-iframe", '*');
  }


  thousands_separators(num) {
    console.log(num)
    if (num) {
      var num_parts = num.toString().split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return num_parts.join(".");
    }
  }
  async applyPromo() {
    //Put Code for Apply Promocode
    let { errors, data } = await getBillingCouponInfoByPromoCode(this.state.promoCode, 'topups')
    if (errors) return alert(errors[0].message)
    const { getBillingCouponInfoByPromoCode: { value, unit, id, stripeCouponId } } = data
    if (id) {
      this.setState({
        promoCodeId: id,
        isPromoCode: true,
        promoCodeAmount: unit === 'PERCENTAGE' ? (this.state.price / 100) * value / 100 : value
      }, async () => {
        const taxInfo = await calculateTaxByCurrency((this.state.price / 100) - this.state.promoCodeAmount, this.state?.currency);
        this.setState({
          taxPayable: taxInfo?.data?.calculateTaxByCurrency?.payable,
          taxRate: taxInfo?.data?.calculateTaxByCurrency?.rate?.rate,
          taxType: taxInfo?.data?.calculateTaxByCurrency?.rate?.type,
        })
      })
    } else {
      alert('No promo code found')
    }
  }

  cancelPromo() {
    //Put Code for Cancel Promocode
    this.setState({
      promoCodeId: '',
      isPromoCode: false,
      promoCodeAmount: 0,
      promoCode: ''
    }, async () => {
      const taxInfo = await calculateTaxByCurrency((this.state.price / 100) - this.state.promoCodeAmount, this.state?.currency);
      this.setState({
        taxPayable: taxInfo?.data?.calculateTaxByCurrency?.payable,
        taxRate: taxInfo?.data?.calculateTaxByCurrency?.rate?.rate,
        taxType: taxInfo?.data?.calculateTaxByCurrency?.rate?.type,
      })
    })
  }

  render() {
    if (this.state.confirmStep === 2) {
      console.log('REDIRECTING')
      return <Redirect to={{
        pathname: `/complete-transaction`,
        search: (this.props.location.search ? this.props.location.search : null),
        state: { companyId: this.state.companyId, card: this.state.selectedCard, intentClientSecret: this.state.intentClientSecret, serviceName: this.state.serviceName }
      }} />
    }

    let paymentRequest = this.stripePaymentRequest

    console.log(this.state)

    return (
      <div className='main-container'>
        <div className='content-container animate__animated animate__fadeIn'>
          <div className='action-btns'>

            <CheckSvg
              width='40px'
              height='40px'
              className='p-2 rounded'
              color='whitesmoke'
              style={{ backgroundColor: '#a489ac', cursor: 'pointer' }}
              disabled={!this.state.selectedCard}
              onClick={() => this.continuePayment(1)}
            />
            <CrossSvg
              width='40px'
              height='40px'
              className='p-2 rounded'
              color='whitesmoke'
              onClick={() => this.doCancel()}
              style={{ backgroundColor: '#ed1c24', cursor: 'pointer', marginLeft: "8px" }}
            />
          </div>
          <h5 className="modal-title" style={{ marginTop: '10px' }}>{this.thousands_separators(this.state.quantity)} {this.state.serviceName == 'Newsletter' ? 'Studio Creative' : this.state.serviceName} Topup</h5>
          {/* <h3 style={{marginTop: '10px'}}>Purchase</h3> */}
          {/* <p key="1" className="product" style={{marginTop: '10px'}}>{this.thousands_separators(this.state.quantity)} {this.state.serviceName == 'Newsletter' ? 'Studio Creative' : this.state.serviceName}</p> */}
          {/* <p>You are purchasing <strong>{this.state.quantity} {this.state.serviceName}</strong> for <strong>{this.renderTopupPrice()}</strong>, which will be credited to <strong>{this.state.companyName}</strong>.</p> */}
          <div className="price-package">
            {/* {this.state.taxPayable &&
                        <p className="grand">Total</p>
                    }
                    {this.state.taxPayable &&
                        <p><strong>{this.renderTax()}</strong> (including tax)</p>
                    }
                    <p className="grand">Total</p>
                    <p className="grand"><strong>{this.renderTopupPrice()}</strong></p> */}

            <p>Total</p>
            <p><strong>{this.renderCurrencyFormatter(this.state.price / 100, this.state?.currency)}</strong></p>
          </div>
          {this.renderMethod()}
          <div style={{ marginTop: '10px' }}>
            <input type="text" name="promo" placeholder="Promo code" value={this.state.promoCode} disabled={this.state.isPromoCode} onChange={(e) => { console.log(e); this.setState({ promoCode: e.target.value }) }} />
            {this.state.isPromoCode ? (<Button variant="synkdPrimary" className="btn-promo" onClick={() => this.cancelPromo()} style={{ marginLeft: '5px' }} >Cancel</Button>) : (<Button variant="synkdPrimary" className="btn-promo" onClick={() => this.applyPromo()} style={{ marginLeft: '5px' }} >✓</Button>)}
          </div>
          <div className="price-package">
            <p>{this.state.taxType}</p>
            <p><strong>{this.renderCurrencyFormatter(this.state.taxPayable, this.state?.currency)}</strong></p>
            {this.state.isPromoCode ? (
              <p>Discount</p>
            ) : ''}
            {this.state.isPromoCode ? (
              <p><strong>{this.renderCurrencyFormatter(this.state.promoCodeAmount, this.state?.currency)}</strong></p>
            ) : ''}
            <p className="grand">Grand Total</p>
            <p className="grand"><strong>{this.renderCurrencyFormatter((this.state.price / 100) - this.state.promoCodeAmount + this.state.taxPayable, this.state?.currency)}</strong></p>
          </div>

          {/* <div className="price-package">
                        <p>Total</p>
                        <p><strong>{this.renderCurrencyFormatter(this.renderTopupPrice(),this.state?.currency)}</strong></p>
                        <p>{this.state.taxName}</p>
                        <p><strong>{this.renderCurrencyFormatter((((this.renderTopupPrice()) * this.state.taxAmount)),this.state?.currency)}</strong></p>
                        <p className="grand">Grand Total</p>
                        <p className="grand"><strong>{this.renderCurrencyFormatter((((this.renderTopupPrice()) + ((this.renderTopupPrice()) * this.state.taxAmount))),this.state?.currency)}</strong></p>
                    </div> */}
          {/* <h5>Select payment method</h5> */}
          {/* <ButtonGroup>
                        <Button variant="secondary" onClick={() => this.selectMethod('card')}>Credit/Debit Card</Button> */}
          {/* {this.state.canUsePaymentRequest && <PaymentRequestButtonElement options={{paymentRequest}} />} */}
          {/* </ButtonGroup> */}
          {/* {this.state.taxPayable &&
                        <p>Total: <strong>{this.renderTax()}</strong> (including tax)</p>
                    } */}

        </div>
        {/* <GeneralDisclaimer /> */}
      </div>
    )
  }
}

export default withRouter(Topup)

import React, { useState, useEffect, useCallback } from 'react'

/** Utils */
import { withRouter, Redirect } from 'react-router'
import { getCompanyCards, buyAttendees, getAttendeeService, getEvent, getBillingCouponInfoByPromoCode, calculateTaxByCurrency } from '../api/calls'
import queryString from 'query-string'

/** Components */
import Button from 'react-bootstrap/Button'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'

/** Assets */
import VisaCard from '../assets/card-providers/dark/visa.png'


const EventAttendees = (props) => {
  const { match, stripe, elements, location } = props

  const [pricingInfo, setPricingInfo] = useState(null)
  const [event, setEvent] = useState(null)
  const [tax, setTax] = useState({})

  const [promoCode, setPromoCode] = useState('')
  const [promoCodeId, setPromoCodeId] = useState('')
  const [promoCodeAmount, setPromoCodeAmount] = useState(0)
  const [isPromoCode, setIsPromoCode] = useState(false)

  const [selectedCard, setSelectedCard] = useState(null)
  const [companyCards, setCompanyCards] = useState([])

  const [confirmStep, setConfirmStep] = useState(0)
  const [intentClientSecret, setIntentClientSecret] = useState(null)

  const getTax = useCallback(async () => {
    if (!pricingInfo) return
    const taxInfo = await calculateTaxByCurrency((pricingInfo.price / 100) - promoCodeAmount, pricingInfo?.currency)
    setTax({
      taxPayable: taxInfo?.data?.calculateTaxByCurrency?.payable,
      taxRate: taxInfo?.data?.calculateTaxByCurrency?.rate?.rate,
      taxType: taxInfo?.data?.calculateTaxByCurrency?.rate?.type,
    })
  }, [pricingInfo])

  const getEventProps = useCallback(async () => {
    try {
      if (!match?.params?.eventId) return
      const { data } = await getEvent(match?.params?.eventId)

      setEvent(data.getEvent)
    } catch (error) {
      console.log(error)
    }
  }, [match?.params?.eventId])

  const getCards = useCallback(async () => {
    try {
      if (!event?.organiser?.company?.id) return
      const { data: { getPaymentCards } } = await getCompanyCards(event?.organiser?.company?.id)

      setCompanyCards(getPaymentCards)
    } catch (error) {
      console.log(error)
    }
  }, [event?.organiser?.company?.id])

  const getService = useCallback(async () => {
    try {
      if (!event) return
      const { data } = await getAttendeeService(event?.id)

      setPricingInfo({
        serviceId: data.getAttendeeService.service.id,
        price: data.getAttendeeService.price,
        quantity: data.getAttendeeService.amount,
        serviceName: data.getAttendeeService.service.name,
        currency: data.getAttendeeService?.currency,
        companyName: data.getAttendeeService.company.name,
        taxName: data.getAttendeeService.company.taxName,
        taxAmount: data.getAttendeeService.company.taxAmount
      })

    } catch (error) {
      console.log(error)
    }
  }, [event])

  const topupAttendees = useCallback(async () => {
    try {
      let { data } = await buyAttendees(event?.id, pricingInfo?.quantity, selectedCard.stripeId, promoCodeId)
      if (data && data.buyAttendees && !intentClientSecret) {
        setIntentClientSecret(data.buyAttendees)
        setConfirmStep(2)
      }
    } catch (error) {
      console.log(error)
    }
  }, [event, pricingInfo, promoCodeId, selectedCard])

  const formatNumber = (value) => {
    if (value) {
      let numParts = value.toString().split('.');
      numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return numParts.join('.');
    }
    return value
  }

  const renderCurrencyFormatter = (price, currency) => {
    if (!currency) return price
    const formatter = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: currency
    })

    return formatter.format(price)
  }

  const applyPromo = useCallback(async () => {
    let { errors, data } = await getBillingCouponInfoByPromoCode(promoCode, 'event-attendees')

    if (errors) return alert(errors[0].message)

    const { getBillingCouponInfoByPromoCode: { value, unit, id, stripeCouponId  } } = data
    if (id) {
      setPromoCodeId(id)
      setIsPromoCode(true)
      setPromoCodeAmount(unit === 'PERCENTAGE' ? (this.state.price / 100) * value / 100 : value)
      getTax()
    } else {
      alert('No promo code found')
    }
  }, [promoCode])

  const cancelPromo = async () => {
    setPromoCodeId('')
    setIsPromoCode(false)
    setPromoCodeAmount(0)
    setPromoCode('')
    getTax()
  }

  const getCardBrandImage = (brand) => {
    brand = brand.toLowerCase()

    let cardImage

    switch (brand) {
      case 'visa':
        cardImage = VisaCard
        break
      default:
        break
    }

    if (cardImage) {
      return <img alt={brand} width='40px' src={cardImage} />
    }
  }

  const renderCardDropdownLine = (card) => {
    return (
      <span>{getCardBrandImage(card?.brand)} **** **** **** {card?.last4} (exp: {card?.exp_month}/{card?.exp_year})</span>
    )
  }

  const doCancel = useCallback(() => {

    let url =
    window.location != window.parent.location
        ? document.referrer
        : document.location.href;
/* window.parent.postMessage("close-iframe", 'https://localhost:8080/admin/company/adminpackageoptions'); */
    window.parent.postMessage("close-iframe", '*');

    // const parsedQuery = queryString.parse(location.search)
    // window.location.replace(parsedQuery['callback'] || 'https://synkd.life')
  }, [location.search])
    
  useEffect(() => {
    getEventProps()
    console.log(match)
  }, [getEventProps])
    
  useEffect(() => {
    getService()
  }, [getService])
    
  useEffect(() => {
    getCards()
  }, [getCards])
    
  useEffect(() => {
    getTax()
  }, [getTax])

  useEffect(() => {
    if (confirmStep === 1) {
      topupAttendees()
    }
  }, [confirmStep, topupAttendees])

  if (confirmStep === 2) {
    return (
      <Redirect
        to={{
          pathname: `/complete-transaction`,
          search: (location.search ? location.search : null),
          state: { companyId: event?.organiser?.company?.id, card: selectedCard, intentClientSecret, serviceName: pricingInfo?.serviceName}
        }}
      />
    )
  }

  return (
    <div className='main-container'>
      <div className='content-container animate__animated animate__fadeIn'>
          <h5 className="modal-title" style={{marginTop: '10px'}}>{formatNumber(pricingInfo?.quantity)} Event Attendees Cap</h5>

          <div className="price-package">
            <p>Total</p>
            <p>
              <strong>{renderCurrencyFormatter(pricingInfo?.price / 100, pricingInfo?.currency)}</strong>
            </p>
          </div>
                    
          <div style={{marginTop: '10px'}}>
              <input
                type="text"
                name="promo"
                placeholder="Promo code"
                value={promoCode}
                disabled={isPromoCode}
                onChange={(e) => setPromoCode(e.target.value)}
              />
              {
                isPromoCode ? (
                  <Button
                    variant="synkdPrimary"
                    className="btn-promo"
                    onClick={() => cancelPromo()}
                    style={{marginLeft: '5px'}}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    variant="synkdPrimary"
                    className="btn-promo"
                    onClick={() => applyPromo()}
                    style={{marginLeft: '5px'}}
                  >
                    Apply
                  </Button>
                )
              }
          </div>

          <div className="price-package">
            <p>{tax.taxType}</p>
            <p>
              <strong>{renderCurrencyFormatter(tax.taxPayable, pricingInfo?.currency)}</strong>
            </p>
            {
              isPromoCode && (
                <>
                  <p>Discount</p>
                  <p>
                    <strong>{renderCurrencyFormatter(promoCodeAmount, pricingInfo?.currency)}</strong>
                  </p>
                </>
              )
            }
            <p className="grand">Grand Total</p>
            <p className="grand">
              <strong>{renderCurrencyFormatter((pricingInfo?.price / 100) - promoCodeAmount + tax.taxPayable, pricingInfo?.currency)}</strong>
            </p>
          </div>

          <div className='purchase-method card-method animate__animated animate__fadeIn'>
            <DropdownButton id="card-dropdown" variant='secondary' title={selectedCard ? renderCardDropdownLine(selectedCard) : 'Select Card'}>
              <Dropdown.ItemText>Saved company cards</Dropdown.ItemText>
              {
                companyCards.map((card) => {
                  return (
                    <Dropdown.Item as="button" key={card.stripeId} onClick={() => setSelectedCard(card)}>
                      {renderCardDropdownLine(card)}
                    </Dropdown.Item>
                  )
                })
              }
            </DropdownButton>
          </div>

          <div className='action-btns'>
            <Button
              variant="synkdPrimary"
              disabled={!selectedCard}
              onClick={() => setConfirmStep(1)}
              className='pay-btn'
            >
              CONFIRM
            </Button>
            <Button
              variant="danger"
              onClick={() => doCancel()}
              className='cancel-btn'
            >
              Cancel
            </Button>
          </div>
      </div>
    </div>
  )
}

export default withRouter(EventAttendees)
